import "./App.css";
import { useEffect, useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Subscribe } from "./Subscribe";

function App() {
	const [city, setCity] = useState("Your City");

	useEffect(() => {
		fetch("https://api.simplenews.in/fetch-city")
			.then((response) => response.json())
			.then((data) => {
				setCity(data.city);
			});
	}, []);

	return (
		<div class="container my-5 ">
			<div class="city-display">{city && <h1 class="mb-4">{city}!</h1>}</div>

			<div>
				<h1 class="mb-4">Simple News</h1>
				<p class="h4 mb-3">{city} News, Simplified</p>
				<p class="mb-4">Get the latest news and updates from {city}, delivered right to your inbox in easy-to-read, bite-sized summaries. No clutter, no spam — just the information that matters.</p>
			</div>

			<div>
				<h2 class="h4 mb-3">Daily or Weekly — You Choose</h2>
				<p>Whether you prefer to stay updated every day or just catch up once a week, we've got you covered.</p>
			</div>

			<div>
				<h2 class="h4 mb-3">No Politics? Your Call</h2>
				<p>Not interested in politics? No problem. Customize your news feed to suit your preferences. Opt-out anytime, and get only the updates you want.</p>
			</div>

			<div>
				<h2 class="h4 mb-3">Completely Free, No Popup Ads</h2>
				<p>No payments, no annoying pop-up ads — just a clean, distraction-free newsletter to keep you informed.</p>
			</div>

			<Subscribe city={city} />
		</div>
	);
}

export default App;
