import React, { useState, useEffect } from "react";

export function Subscribe({ city }) {
	const [email, setEmail] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();

		const payload = {
			email: email,
			city: city,
		};

		try {
			const response = await fetch("https://api.simplenews.in/subscribe", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				console.log("Subscription successful!");
			} else {
				console.error("Failed to subscribe. Please try again.");
			}
		} catch (error) {
			console.error("Error:", error);
			alert("Failed to subscribe. Please try again.");
		}
	};

	return (
		<div className="text-center mt-5">
			<h2 className="h4 mb-3">Sign Up in Seconds</h2>
			<p>Join thousands of locals staying in the loop. Subscribe now and get your first update today.</p>
			<form
				className="d-inline-block w-50"
				onSubmit={handleSubmit}
			>
				<input
					type="email"
					className="form-control mb-3"
					placeholder="Enter your email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<button
					type="submit"
					className="btn btn-primary btn-lg w-100"
				>
					Subscribe Now
				</button>
			</form>
		</div>
	);
}
